import * as React from 'react';
import physio1_slide_blur_smallJpg from "../../images/physio/physio1_slide_blur_small.jpg";
import physio2_slide_blur_smallJpg from "../../images/physio/physio2_slide_blur_small.jpg";
import {Component} from "react";

export class MainRevolutionSlider extends Component {

  /**
   * Reference to jQuery.
   */
  private readonly jquery: any;

  /**
   * ID of the div that contains the `ul` of the revolution slider to configure.
   */
  private readonly className: string;

  /**
   * Revolution slider configuration
   */
  private readonly config: any;

  constructor(props: any) {
    super(props);
    // @ts-ignore
    this.jquery = jQuery || $ || window.jQuery || window.$;
    if (!this.jquery) {
      throw new TypeError("No jQuery object found!");
    }

    this.className = "rev_slider";
    this.config = {
      sliderType: "standard",
      jsFileLocation: "js/revolution-slider/js/",
      sliderLayout: "auto",
      dottedOverlay: "none",
      delay: 5000,
      navigation: {
        keyboardNavigation: "off",
        keyboard_direction: "horizontal",
        mouseScrollNavigation: "off",
        onHoverStop: "off",
        touch: {
          touchenabled: "on",
          swipe_threshold: 75,
          swipe_min_touches: 1,
          swipe_direction: "horizontal",
          drag_block_vertical: false
        },
        arrows: {
          style: "gyges",
          enable: true,
          hide_onmobile: false,
          hide_onleave: true,
          hide_delay: 200,
          hide_delay_mobile: 1200,
          tmp: '',
          left: {
            h_align: "left",
            v_align: "center",
            h_offset: 0,
            v_offset: 0
          },
          right: {
            h_align: "right",
            v_align: "center",
            h_offset: 0,
            v_offset: 0
          }
        },
        bullets: {
          enable: true,
          hide_onmobile: true,
          hide_under: 800,
          style: "hebe",
          hide_onleave: false,
          direction: "horizontal",
          h_align: "center",
          v_align: "bottom",
          h_offset: 0,
          v_offset: 30,
          space: 5,
          tmp: '<span class="tp-bullet-image"></span><span class="tp-bullet-imageoverlay"></span><span class="tp-bullet-title"></span>'
        }
      },
      responsiveLevels: [1240, 1024, 778],
      visibilityLevels: [1240, 1024, 778],
      gridwidth: [1170, 1024, 778, 480],
      gridheight: [720, 768, 960, 720],
      lazyType: "none",
      parallax: "mouse",
      parallaxBgFreeze: "off",
      parallaxLevels: [2, 3, 4, 5, 6, 7, 8, 9, 10, 1],
      shadow: 0,
      spinner: "off",
      stopLoop: "on",
      stopAfterLoops: 0,
      stopAtSlide: -1,
      shuffle: "off",
      autoHeight: "off",
      fullScreenAutoWidth: "off",
      fullScreenAlignForce: "off",
      fullScreenOffsetContainer: "",
      fullScreenOffset: "0",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        nextSlideOnWindowFocus: "off",
        disableFocusListener: false,
      }
    };
  }

  public componentDidMount(): void {
    // TODO jQuery is probably not needed for this task!
    this.jquery(document).ready(() => {
      this.jquery(`.${this.className}`).revolution(this.config);
    });
  }

  public render() {
    return (
      <section id="home" className="divider">
        <div className="container-fluid p-0">
          <div className="rev_slider_wrapper">
            <div className="rev_slider" data-version="5.0">
              <ul>
                { MainRevolutionSlider.renderSlide1() }
                { MainRevolutionSlider.renderSlide2() }
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }

  private static renderSlide1() {
    return <li>
      <img src={physio1_slide_blur_smallJpg} alt="" data-bgposition="center bottom"
           data-bgfit="cover"
           data-bgrepeat="no-repeat" className="rev-slidebg" data-bgparallax="6"
           data-no-retina="true"/>

      <div
        className="tp-caption tp-resizeme text-uppercase font-raleway text-center text-white"
        id="rs-1-layer-1"

        data-x="['center']"
        data-hoffset="['0']"
        data-y="['middle']"
        data-voffset="['-80']"
        data-fontsize="['64','64','54','24']"
        data-lineheight="['95']"

        data-width="none"
        data-height="none"
        data-whitespace="nowrap"
        data-transform_idle="o:1;s:500"
        data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
        data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
        data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
        data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
        data-start="1000"
        data-splitin="none"
        data-splitout="none"
        data-responsive_offset="on"
        style={{zIndex: 5, whiteSpace: 'nowrap', fontWeight: 'bold'}}>PhysioFit <span
        className="text-theme-colored"> Bramfeld</span>
      </div>

      <div className="tp-caption tp-resizeme text-center font-raleway text-white"
           id="rs-1-layer-2"

           data-x="['center']"
           data-hoffset="['0']"
           data-y="['middle']"
           data-voffset="['0']"
           data-fontsize="['28']"
           data-lineheight="['34']"

           data-width="none"
           data-height="none"
           data-whitespace="nowrap"
           data-transform_idle="o:1;s:500"
           data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
           data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
           data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
           data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
           data-start="1400"
           data-splitin="none"
           data-splitout="none"
           data-responsive_offset="on"
           style={{zIndex: 5, whiteSpace: 'nowrap', fontWeight: 'bold'}}>Therapie und Training<br/>
      </div>

      <div className="tp-caption tp-resizeme"
           id="rs-1-layer-3"

           data-x="['center']"
           data-hoffset="['0']"
           data-y="['middle']"
           data-voffset="['80']"

           data-width="none"
           data-height="none"
           data-whitespace="nowrap"
           data-transform_idle="o:1;s:500"
           data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
           data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
           data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
           data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
           data-start="1600"
           data-splitin="none"
           data-splitout="none"
           data-responsive_offset="on"
           style={{zIndex: 5, whiteSpace: 'nowrap', fontWeight: 'bold'}}><a
        className="btn btn-default btn-lg btn-circled mr-10" href="#contact">Termin
        vereinbaren</a> <a
        className="btn btn-colored btn-lg btn-circled btn-theme-colored" href="#about">Mehr
        Details</a>
      </div>
    </li>;
  }

  private static renderSlide2() {
    return <li>
      <img src={physio2_slide_blur_smallJpg} alt="" data-bgposition="center bottom"
           data-bgfit="cover"
           data-bgrepeat="no-repeat" className="rev-slidebg" data-bgparallax="6"
           data-no-retina="true"/>

      <div
        className="tp-caption tp-resizeme text-uppercase font-raleway text-center text-white"
        id="rs-1-layer-1"

        data-x="['center']"
        data-hoffset="['0']"
        data-y="['middle']"
        data-voffset="['-80']"
        data-fontsize="['64','64','54','24']"
        data-lineheight="['95']"

        data-width="none"
        data-height="none"
        data-whitespace="nowrap"
        data-transform_idle="o:1;s:500"
        data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
        data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
        data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
        data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
        data-start="1000"
        data-splitin="none"
        data-splitout="none"
        data-responsive_offset="on"
        style={{zIndex: 5, whiteSpace: 'nowrap', fontWeight: 'bold'}}>Gesundheit und <span
        className="text-theme-colored"> Fitness</span>
      </div>

      <div className="tp-caption tp-resizeme text-center font-raleway text-white"
           id="rs-1-layer-2"

           data-x="['center']"
           data-hoffset="['0']"
           data-y="['middle']"
           data-voffset="['0']"
           data-fontsize="['28']"
           data-lineheight="['34']"

           data-width="none"
           data-height="none"
           data-whitespace="nowrap"
           data-transform_idle="o:1;s:500"
           data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
           data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
           data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
           data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
           data-start="1400"
           data-splitin="none"
           data-splitout="none"
           data-responsive_offset="on"
           style={{zIndex: 5, whiteSpace: 'nowrap', fontWeight: 'bold'}}>Bei uns sind Sie in den besten Händen!<br/>
      </div>

      <div className="tp-caption tp-resizeme"
           id="rs-1-layer-3"

           data-x="['center']"
           data-hoffset="['0']"
           data-y="['middle']"
           data-voffset="['80']"

           data-width="none"
           data-height="none"
           data-whitespace="nowrap"
           data-transform_idle="o:1;s:500"
           data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
           data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
           data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
           data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
           data-start="1600"
           data-splitin="none"
           data-splitout="none"
           data-responsive_offset="on"
           style={{zIndex: 5, whiteSpace: 'nowrap', fontWeight: 'bold'}}><a
        className="btn btn-default btn-lg btn-circled mr-10" href="#contact">Termin
        vereinbaren</a> <a
        className="btn btn-colored btn-lg btn-circled btn-theme-colored" href="#about">Mehr
        Details</a>
      </div>
    </li>;
  }
}
