import * as React from "react";

export function GoogleMaps() {
  return <section id="maps" data-bg-img="images/pattern/p7.png">
    <div className="container">
      <div className="section-title text-center">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <h2 className="title text-uppercase">Hier finden Sie uns</h2>
          </div>
        </div>
      </div>
      <div className="row mtli-row-clearfix">
        <div className="col-md-8 col-md-offset-2">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1149.6404672607073!2d10.076675867409671!3d53.61745600583699!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b18981f85f06f9%3A0x11ee3e1bb7c8588e!2sBramfelder+Chaussee+291%2C+22177+Hamburg!5e0!3m2!1sde!2sde!4v1539106815280" width="600" height="450" frameBorder="0" style={{ border: "0" }} allowFullScreen={true}/>
        </div>
      </div>
    </div>
  </section>;
}
