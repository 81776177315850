import * as moment from 'moment';
import {IOpeningHour, IOpeningHours} from 'src/models/OpeningHours';
import { IService } from 'src/models/Service';
import {IFeetReflexMassage} from "../models/FeetReflex";

export const OPENING_HOURS: IOpeningHours = { // FIXME Make private
  mon: [{ from: moment().hour(8).minute(0), to: moment().hour(13).minute(0) }, { from: moment().hour(14).minute(0), to: moment().hour(19).minute(0) }],
  tue: [{ from: moment().hour(8).minute(0), to: moment().hour(13).minute(0) }, { from: moment().hour(14).minute(0), to: moment().hour(19).minute(0) }],
  wed: [{ from: moment().hour(8).minute(0), to: moment().hour(13).minute(0) }, { from: moment().hour(14).minute(0), to: moment().hour(19).minute(0) }],
  thu: [{ from: moment().hour(8).minute(0), to: moment().hour(13).minute(0) }, { from: moment().hour(14).minute(0), to: moment().hour(19).minute(0) }],
  fri: [{ from: moment().hour(8).minute(0), to: moment().hour(12).minute(0) }],
  sat: [],
  sun: [],
};

export const SERVICES: IService[] = [ // FIXME Make private
  { title: 'Massage', duration: 20, price: 28 },
  { title: 'Massage inkl. Heißer Rolle', duration: 30, price: 43 },
  { title: 'Massage inkl. Fango', duration: 40, price: 40 },
  { title: 'Krankengymnastik', duration: 20, price: 33 },
  { title: 'Krankengymnastik auf Neurologischer Basis (ZNS)', duration: 30, price: 38 },
  { title: 'Manuelle Lymphdrainage', duration: 30, price: 33 },
  { title: 'Manuelle Lymphdrainage', duration: 45, price: 54 },
  { title: 'Manuelle Lymphdrainage', duration: 60, price: 65 },
  { title: 'Fango', duration: 20, price: 18 },
  { title: 'Heiße Rolle', duration: 10, price: 15 },
  { title: 'Heißluft / Rotlicht', duration: 20, price: 13 },
];

export class MasterDataService {
  public static getCurrentOpeningHour(): IOpeningHour | null {
    const todaysOpeningHours = this.getTodaysOpeningHours();

    if (todaysOpeningHours.length === 0) {
      return null;
    }

    if (todaysOpeningHours.length === 1) {
      return todaysOpeningHours[0];
    }

    // Try to find the current opening time
    const now = moment();
    const current = todaysOpeningHours.find((h) => {
      return h.from.hour() <= now.hour() && h.to.hour() >= now.hour();
    });

    // If no opening time is found, check if there is one coming up on this day
    if (current == null) {
      return todaysOpeningHours.find((h) => {
        return h.from.hour() >= now.hour();
      }) || null;
    }

    return current;
  }

  public static getTodaysOpeningHours(): IOpeningHour[] {
    const today = moment();
    const dow = today.day(); // 0 - sunday, 1 - monday, ...

    switch (dow) {
      case 0: return OPENING_HOURS.sun;
      case 1: return OPENING_HOURS.mon;
      case 2: return OPENING_HOURS.tue;
      case 3: return OPENING_HOURS.wed;
      case 4: return OPENING_HOURS.thu;
      case 5: return OPENING_HOURS.fri;
      case 6: return OPENING_HOURS.sat;
      default: return [];
    }
  }

  public static getOpeningHours(): Promise<IOpeningHours> {
    return Promise.resolve(OPENING_HOURS);
  }

  public static getServices(): Promise<IService[]> {
    return Promise.resolve(SERVICES);
  }

  public static getFeetReflex(): IFeetReflexMassage[] {
    return [
      {
        title: "Klassische Fußreflexzonenmassage",
        subtitle: "",
        points: [
          "Fördert die Tiefenentspannung",
          "Stressabbauend",
        ],
        price: 65,
        duration: 60
      },
      {
        title: "Organbezogene Fußreflexzonenmassage",
        subtitle: "",
        points: [
          "Fördert das Entschlacken von Giftstoffen",
          "Behebt Verdauungsstörungen",
        ],
        price: 45,
        duration: 40
      },
      {
        title: "Fußreflexzonenmassage am Bewegungsapparat",
        subtitle: "",
        points: [
          "Löst Blockaden in den Gelenken",
          "Durchblutungsfördernd bei Diabetes",
        ],
        price: 45,
        duration: 40
      },
      {
        title: "Fußreflexzonenmassage Obere Extremitäten",
        subtitle: "",
        points: [
          "Lindert einen Hörsturz",
          "Besserung bei Halswirbelsäulen- / Schulterschmerzen",
        ],
        price: 45,
        duration: 40
      }
    ];
  }
}
