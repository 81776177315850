import * as React from 'react';
import { ContactForm } from 'src/components/contact-form/ContactForm';
import { MainRevolutionSlider } from 'src/components/revolution-slider/MainRevolutionSlider';
import { OfficeImagesRevolutionSlider } from 'src/components/revolution-slider/OfficeImagesRevolutionSlider';
import { SectionTitle } from 'src/components/section-title/SectionTitle';
import { Section } from 'src/components/section/Section';
import { PATTERN } from 'src/images/pattern/Pattern';
import { IFeetReflexMassage } from 'src/models/FeetReflex';
import physio6_sqrJpg from "../images/physio/physio6_sqr.jpg";
import spine_sqr_smJpg from "../images/physio/spine_sqr_sm.jpg";
import lymph2_sqrJpg from "../images/physio/lymph2_sqr.jpg";
import physio5_sqrJpg from "../images/physio/physio5_sqr.jpg";
import physio2Jpg from "../images/physio/physio2.jpg";
import fussmassageJpg from "../images/physio/fussmassage.jpg";
import massage2_wideJpg from "../images/physio/massage2_wide.jpg";
import tapeJpg from "../images/physio/tape.jpg";
import christmas2Jpg from "../images/physio/christmas/christmas2.jpg";
import infraredJpg from "../images/physio/infrared.jpg";
import fango1_smallJpg from "../images/physio/fango1_small.jpg";
import SchlingentischJpg from "../images/physio/Schlingentisch.jpg";
import massage3Jpg from "../images/physio/massage3.jpg";
import physio5Jpg from "../images/physio/physio5.jpg";
import towel1Jpg from "../images/physio/towel1.jpg";
import jawJpg from "../images/physio/jaw.jpg";
import hotstone1Jpg from "../images/physio/hotstone1.jpg";
import lymph2Jpg from "../images/physio/lymph2.jpg";
import schroepfenJpg from "../images/physio/schroepfen.jpg";
import easterBgSmJpg from "../images/physio/easter/easterBgSm.jpg";
import { MasterDataService } from 'src/services/MasterDataService';
import { IService } from 'src/models/Service';
import { GoogleMaps } from 'src/components/google/Maps';

interface IHomeProps {
  services: IService[];
}

export function Home(props: IHomeProps) {
  return (
    <div>
      <MainRevolutionSlider/>

      {renderIntro()}

      {renderRow2()}

      <div data-bg-img={PATTERN.p8}>
        {renderMainServices()}
        {renderWellnessServices()}
        {renderOtherServices()}
      </div>

      {renderParallaxDivider(massage2_wideJpg)}

      {renderPriceTable(props.services)}

      {renderJobangebot()}

      <Section id="contact">
        <SectionTitle title="Kontakt" subtitle=""/>
        <ContactForm/>
      </Section>

      <Section id="praxis">
        <SectionTitle title="Praxis" subtitle="Wir freuen uns auf Ihren Besuch!"/>
        <OfficeImagesRevolutionSlider/>
      </Section>

      <GoogleMaps/>
    </div>
  );
}

function renderIntro() {
  return <section id="about">
    <div className="container">
      <div className="section-content">
        <div className="row">
          <div className="col-md-7">
            <div className="meet-doctors text-left">
              <h6 className="text-uppercase letter-space-5 mt-0">Immer in guten Händen</h6>
              <h2 className="text-uppercase mt-20">Über <span className="text-theme-colored">PhysioFit</span>
              </h2>
              <div style={{ fontSize: "1rem" }}>
                <p>Wir freuen uns herzlich, Sie auf unserer Homepage begrüßen zu dürfen!</p>
                <p>Das PhysioFit-Team bietet eine umfangreiche und ganzheitliches Angebot für Ihre Gesundheit
                  an.</p>
                <p>Auf dieser Webseite finden Sie Informationen zu unseren &Ouml;ffnungszeiten, unseren
                  Dienstleistungen, der Praxis und dem Team und ein <a href="#contact"
                                                                        style={{textDecoration: "underline"}}>Kontakt-Formular</a>.
                  Bei weiteren Fragen zu unseren Leistungen oder der Physiotherapie im Allgemeinen, kontaktieren
                  Sie uns gerne ganz unverbindlich!</p>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="thumb text-center">
                  <img className="img-circle border-7px" style={{maxWidth: "150px"}} src={physio6_sqrJpg}
                        alt=""/>
                  <h4 className="icon-box-title mt-20 mb-20 text-uppercase letter-sapce-3 font-weight-400">
                    <a href="#" className="text-theme-colored">Krankengymnastik</a>
                  </h4>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="thumb text-center">
                  <img className="img-circle border-7px" style={{maxWidth: "150px"}} src={lymph2_sqrJpg}
                        alt=""/>
                  <h4 className="icon-box-title mt-20 mb-20 text-uppercase letter-sapce-3 font-weight-400">
                    <a href="#">Lymphdrainage</a>
                  </h4>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="thumb text-center">
                  <img className="img-circle border-7px" style={{maxWidth: "150px"}} src={physio5_sqrJpg}
                        alt=""/>
                  <h4 className="icon-box-title mt-20 mb-20 text-uppercase letter-sapce-3 font-weight-400">
                    <a href="#" className="text-theme-colored">PNF &amp; ZNS</a>
                  </h4>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="thumb text-center">
                  <img className="img-circle border-7px" style={{maxWidth: "150px"}} src={spine_sqr_smJpg}
                        alt=""/>
                  <h4 className="icon-box-title mt-20 mb-20 text-uppercase letter-sapce-3 font-weight-400">
                    <a href="#">Manuelle Therapie</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="border-6px p-30 pt-10 pb-0">
              <h3><i className="fa fa-clock-o text-theme-colored"/> Unsere Öffnungszeiten</h3>
              <p><b>Termine nach Vereinbarung</b></p>

              <div className="opening-hours">
                <ul className="list-border">
                  <li className="clearfix"><span><b>Montag</b></span>
                    <div className="value pull-right"> 8:00 - 13:00<br/>14:00 - 19:00</div>
                  </li>
                  <li className="clearfix"><span><b>Dienstag</b></span>
                    <div className="value pull-right"> 8:00 - 13:00<br/>14:00 - 19:00</div>
                  </li>
                  <li className="clearfix"><span><b>Mittwoch</b></span>
                    <div className="value pull-right"> 8:00 - 13:00<br/>14:00 - 19:00</div>
                  </li>
                  <li className="clearfix"><span><b>Donnerstag</b></span>
                    <div className="value pull-right"> 8:00 - 13:00<br/>14:00 - 19:00</div>
                  </li>
                  <li className="clearfix"><span><b>Freitag</b></span>
                    <div className="value pull-right"> 8:00 - 12:00</div>
                  </li>
                </ul>
                <p className="mt-10 mb-10">An Feiertagen oder bei Ausfällen können die Öffnungszeiten
                  abweichen.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>;
}

function renderRow2() {
  const massageFn = (data: IFeetReflexMassage) => {
    return (
      <div className="massage">
        <h4>{data.title}</h4>
        <p>{data.subtitle}</p>
        <ul className="list angle-double-right">
          {data.points.map((point, i) => {
            return (<li key={i}>{point}</li>);
          })}
        </ul>
        <b>{data.duration} Minuten - {data.price} €</b>
      </div>
    );
  };

  const reflex = (
    <div className="col-md-6 col-lg-offset-1 p-sm-40 pt-sm-0 pt-20 pb-20 pr-lg-40 ml-md-10 pr-md-50 text-left">
      {MasterDataService.getFeetReflex().map(massageFn)}
      <hr/>
      <p>Fußreflexzonen-Massagen kann bei weiteren Erkrankungen wie bspw. Hörstürzen und
        Nasennebenhölen-Problemen positive Effekte erzielen.</p>
    </div>
);

  return <section id="massage" data-bg-img={PATTERN.p3}>
    <div className="container">
      <div className="section-title text-center">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <h2 className="title text-uppercase">Wie w&auml;re es mit einer <span
              className="text-black font-weight-300">Fußreflexzonen-Massage</span></h2>
            <p className="text-uppercase letter-space-4">Eine wahre Wohltat</p>
          </div>
        </div>
      </div>
      <div className="bg-lighter bg-img-right-top sm-no-bg mt-sm-0" data-bg-img={fussmassageJpg}>
        <div className="row">
          {reflex}
        </div>
      </div>
    </div>
  </section>;
}

interface IServiceInterface {
  title: string; text: string;
  img: any;
  prices?: { price: number, label: string }[];
}

function mapServiceToHTML(service: IServiceInterface, index: number) {
  let priceParagraph: any = "";

  if (service.prices != null) {
    const { prices } = service;

    priceParagraph = (
      <p>
        <ul>
          {prices.map((price, priceIndex) => {
            return (
              <li key={priceIndex}><b>{price.label}</b> - {price.price} €</li>
            );
          })}
        </ul>
      </p>
    );
  }

  return <div key={index} className="col-md-4">
    <div className="bg-white class-item box-hover-effect mb-20 effect1 mb-md-30">
      <div className="thumb" style={{minHeight: "28rem"}}><img alt="featured project" src={service.img} className="img-responsive img-fullwidth"/>
        <div className="content text-left p-25 pt-0 flip">
          <h4 className="text-uppercase text-theme-colored font-weight-800">
            {service.title}
          </h4>
          <div className="line-bottom  mt-10 mb-10"/>
          <p>
            {service.text}
          </p>
          {priceParagraph}
        </div>
      </div>
    </div>
  </div>;
}

function renderMainServices() {
  const services = [
    { title: "Krankengymnastik", img: physio2Jpg, text: "Wir bieten Ihnen allgemeine Krankengymnastik mit persönlicher und professioneller Betreuung, passend zu Ihren Bedürfnissen." },
    { title: "Klassische Massage", img: massage3Jpg, text: "Neben der Physiotherapie bieten wir klassische Massagen zur Entspannung der Muskulatur und Lösen von Verspannungen." },
    { title: "Medical Taping / Kinesio Tape", img: tapeJpg, text: "Bei Muskelschmerzen oder als Präventionsmaßnahme bei forcierten Bewegungen bieten wir Ihnen das Tapen der betroffenen Region an." },
    { title: "Heißluft / Rotlicht", img: infraredJpg, text: "Heißluft bzw. Rotlicht gibt eine milde Wärme zur Vorbereitung des Gewebes für weitere Behandlungen ab." },
    { title: "Fango", img: fango1_smallJpg, text: "Die Fango ist eine wärme-intensivere Alternative zu Rotlicht / Heißluft bei längerer Wärmewirkung." },
    { title: "Schlingentisch-Therapie", img: SchlingentischJpg, text: "Schmerztherapie zur Entlastung der Gelenke und der Wirbelsäule." },
    { title: "Heiße Rolle", img: towel1Jpg, text: "Die Heiße Rolle ist ein unterstützendes Therapieverfahren aus der Hydrotherapie." },
  ];

  const serviceBoxes = services.map(mapServiceToHTML);

  return <section>
    <div id="services" className="container">
      <div className="section-title text-center">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <h2 className="title text-uppercase">Unsere<span
              className="text-black font-weight-300"> Dienstleistungen</span></h2>
            <p className="text-uppercase letter-space-4">Lassen Sie es sich gutgehen.</p>
          </div>
        </div>
      </div>
      <div className="row mtli-row-clearfix" id="Krankengymnastik">
        {serviceBoxes}
      </div>
    </div>
  </section>;
}

function renderWellnessServices() {
  const serviceBoxes = [
    {
      title: "HOT STONE-MASSAGE",
      img: hotstone1Jpg,
      text: "Eine wohltuende Kombination aus Massage, Thermotherapie und Energiearbeit. Dabei wird die ganze Rückseite des Körpers behandelt.",
      prices: [
        { price: 40, label: '30 Minuten' },
        { price: 55, label: '45 Minuten' },
        { price: 90, label: '60 Minuten' },
      ]
    },
    {
      title: "SCHRÖPFEN",
      img: schroepfenJpg,
      text: "Lindern von Verspannungen, Schmerzen und anderen körperlichen Erkrankungen durch Erzeugen eines Unterdrucks auf der Haut.",
      prices: [
        { price: 33, label: '20 Min. Feuerschröpfmassage + 10 Min. Fango' },
        { price: 15, label: '10 Min. Feuer-Stand Glas' },
        { price: 28, label: '20 Min. Schröpfmassage' },
        { price: 10, label: '10 Min. Schröpf-Stand Glas' },
      ]
    },
  ].map(mapServiceToHTML);

  return <section>
    <div id="wellness" className="container">
      <div className="section-title text-center">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <h2 className="title text-uppercase">Wellness</h2>
            <p className="text-uppercase letter-space-4">Eine wahre Wohltat</p>
          </div>
        </div>
      </div>
      <div className="row mtli-row-clearfix" id="Krankengymnastik">
        {serviceBoxes}
      </div>
    </div>
  </section>;
}

function renderOtherServices() {
  const services = [
    { title: "PNF bei ZNS", img: physio5Jpg, text: "Die Propriozeptive Neuromuskuläre Fazilitation (PNF) ist eine dreidimensionale physiotherapeutische Behandlungsmethode, die bei Patienten, Anwendung findet, bei denen das Bewegungsverhalten durch eine Erkrankung, Verletzung, Operation oder Degeneration gestört ist."},
    { title: "Cranio Mandibuläre Dysfunktion", img: jawJpg, text: "Bei der \"CMD\"-Therapie werden funktionelle, biochemische und psychische Fehlregulationen von Muskel- oder Gelenkfunktionen der Kiefergelenke behandelt."},
    { title: "Manuelle Lymphdrainage / Kompressionstherapie", img: lymph2Jpg, text: " Das Anwendungsgebiet der manuellen Lymphdrainage ist breit gefächert. Sie dient vor allem als Ödem- und Entstauungstherapie, bspw. nach Traumata oder Operationen. Besonders häufig wird die Therapie nach einer Tumorbehandlung bzw. Lymphknotenentfernung verschrieben."},
  ];

  const serviceBoxes = services.map(mapServiceToHTML);

  return <section>
    <div id="services" className="container">
      <div className="section-title text-center">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <h2 className="title text-uppercase">Sonstige<span
              className="text-black font-weight-300"> Leistungen</span></h2>
          </div>
        </div>
      </div>
      <div className="row mtli-row-clearfix" id="Krankengymnastik">
        {serviceBoxes}
      </div>
    </div>
  </section>;
}

// region Christmas

function renderChristmasHeader() {
  return <section id="christmas-top" style={{borderTop: "1rem #dcdcdc solid", borderBottom: "1rem #dcdcdc solid", backgroundSize: "cover", backgroundPosition: "center", backgroundImage: `url(${christmas2Jpg})`}}>
      <div className="container">
      <div className="section-title text-center">
      <div className="row">
      <div className="col-md-8 col-md-offset-2">
      <h2 className="title text-uppercase">Weihnachts-Angebote</h2>
      <h3>Schenken Sie Entspannung &amp; Freude!</h3>
    </div>
    </div>
    </div>
    <div className="row mtli-row-clearfix">
      <div className="col-md-8 col-md-offset-2">
      <p className="text-center">
      <a className="btn btn" style={{background: "#FFAB00", color: "#333333", fontWeight: "bold"}} href="#christmas">
      Jetzt Angebote anschauen
    </a>
    </p>
    </div>
    </div>
    </div>
    </section>
  ;
}

function renderChristmasBody() {
  return <section id="christmas" style={{borderTop: "1rem #dcdcdc solid", borderBottom: "1rem #dcdcdc solid", backgroundImage: `url(${PATTERN.p5})`}}>
    <div className="container pb-0 pt-70">
      <div className="section-title text-center">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <h2 className="text-uppercase title" style={{fontSize: "2rem", color: 'black'}}>🎅 Weihnachts-Angebote 🎄</h2>
          <h4>
            Schenken Sie Freude & Entspannung! Folgende Angebote bieten wir über die Weihnachtszeit an.
          </h4>
        </div>
      </div>
    </div>
    <div className="section-content">
      <div className="row ml-md-0 multi-row-clearfix">
        <div className="col-md-8 col-md-offset-2 p-0 pr-15 pb-15 mb-30">
        <table className="pricing" style={{width: "100%", fontSize: "1.2rem"}}>
          <thead>
            <tr>
              <th className="text-left">Angebot</th>
              <th className="text-center">Preis</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-left">Massage <small>(20 Minuten)</small></td>
              <td className="text-center">20 € <span style={{textDecoration: "line-through"}}>25 €</span></td>
            </tr>
            <tr>
              <td className="text-left">Massage + Heißluft <small>(30 Minuten)</small></td>
              <td className="text-center">30 € <span style={{textDecoration: "line-through"}}>35 €</span></td>
            </tr>
            <tr>
              <td className="text-left">Massage + Fango <small>(40 Minuten)</small></td>
              <td className="text-center">31 € <span style={{textDecoration: "line-through"}}>37 €</span></td>
            </tr>
            <tr>
              <td className="text-left">Rabatt auf die 5er-Bonuskarte</td>
              <td className="text-center">20%</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
    <div className="section-title text-center">
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <h3 className="text-uppercase title">Geschenk-Gutscheine möglich!</h3>
        </div>
      </div>
    </div>
    </div>
  </section>;
}

// endregion

// region Easter

function renderEasterHeader() {
  const bgStyle = {
    background: "#808080a3",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: "0.475rem",
    paddingBottom: "0.475rem",
  };

  return <section id="easter-top" style={{borderTop: "1rem #dcdcdc solid", borderBottom: "1rem #dcdcdc solid", backgroundSize: "cover", backgroundPosition: "center", backgroundImage: `url(${easterBgSmJpg})`}}>
    <div className="container">
      <div className="section-title text-center">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <h2 className="title text-uppercase"><span style={bgStyle}>Oster-Angebote</span></h2>
            <h3 style={{color: "white"}}><span style={bgStyle}>Schenken Sie Entspannung &amp; Freude!</span></h3>
          </div>
        </div>
      </div>
      <div className="row mtli-row-clearfix">
        <div className="col-md-8 col-md-offset-2">
          <p className="text-center">
            <a className="btn btn" style={{background: "#FFAB00", color: "#fff", fontWeight: "bold"}} href="#easter">
              Jetzt Angebote anschauen
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>
    ;
}

function renderEasterBody() {
  return <section id="easter" style={{borderTop: "1rem #dcdcdc solid", borderBottom: "1rem #dcdcdc solid", backgroundImage: `url(${PATTERN.p5})`}}>
    <div className="container pb-0 pt-70">
      <div className="section-title text-center">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <h2 className="text-uppercase title" style={{fontSize: "2rem", color: 'black'}}>🐰 Oster-Angebote 🌼</h2>
            <h4>
              Schenken Sie Freude & Entspannung! Folgende Angebote bieten wir über die Osterzeit an.
            </h4>
          </div>
        </div>
      </div>
      <div className="section-content">
        <div className="row ml-md-0 multi-row-clearfix">
          <div className="col-md-8 col-md-offset-2 p-0 pr-15 pb-15 mb-30">
            <table className="pricing" style={{width: "100%", fontSize: "1.2rem"}}>
              <thead>
              <tr>
                <th className="text-left">Angebot</th>
                <th className="text-center">Preis</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td className="text-left">Massage <small>(20 Minuten)</small></td>
                <td className="text-center">20 € <span style={{textDecoration: "line-through"}}>25 €</span></td>
              </tr>
              <tr>
                <td className="text-left">Massage + Heißluft <small>(30 Minuten)</small></td>
                <td className="text-center">30 € <span style={{textDecoration: "line-through"}}>35 €</span></td>
              </tr>
              <tr>
                <td className="text-left">Massage + Fango <small>(40 Minuten)</small></td>
                <td className="text-center">31 € <span style={{textDecoration: "line-through"}}>37 €</span></td>
              </tr>
              <tr>
                <td className="text-left">Rabatt auf die 5er-Bonuskarte</td>
                <td className="text-center">20%</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="section-title text-center">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <h3 className="text-uppercase title">Geschenk-Gutscheine möglich!</h3>
          </div>
        </div>
      </div>
    </div>
  </section>;
}

// endregion Easter
function renderPriceTable(services: IService[]) {
  return <section id="pricing">
    <div className="container pb-0 pt-70">
      <div className="section-title text-center">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <h2 className="text-uppercase title">Preise</h2>
          </div>
        </div>
      </div>
      <div className="section-content mb-30">
        <div className="row ml-md-0 multi-row-clearfix">
          <div className="col-md-8 col-md-offset-2 p-0 pr-15 pb-15 mb-30" style={{ fontSize: "1rem" }}>
            <table className="pricing table">
              <thead>
              <tr>
                <th className="text-left">Selbstzahlerleistungen</th>
                <th className="text-center">Dauer</th>
                <th className="text-right">Preis</th>
              </tr>
              </thead>
              <tbody id="pricing-table-body">
              {
                services.map((service, index) => {
                  return <tr key={index}>
                    <td className="text-left">{service.title}</td>
                    <td className="text-center">{service.duration} Minuten</td>
                    <td className="text-right">{service.price} €</td>
                  </tr>;
                })
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>;
}

function renderJobangebot() {
  return <section id="jobs" data-bg-img={PATTERN.p5}>
    <div className="container pb-0 pt-70">
      <div className="section-title text-center">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <h2 className="text-uppercase title">Jobangebot<span className="text-black font-weight-300"/></h2>
            <p className="text-uppercase letter-space-4 mb-20">Werde Teil unseres Teams</p>
            <div style={{ fontSize: "1rem" }}>
              <p>
                Wir sind ein kleines, gut gelauntes Team und arbeiten überwiegend mit orthopädisch- chirurgischen und
                neurologischen Patienten.
              </p>
                <p>
                  Wir suchen Verstärkung für unser junges Team in der Bramfelder Chaussee 291. Als Basis oder auf
                  Teilzeit
                  möglich.
                </p>

                <p>
                  Beginn und Umfang Ihrer Tätigkeit in einem unbefristetem Arbeitsverhältnis werden nach Ihren Wünschen
                  und Qualifikationen vereinbart.
                </p>
              </div>
          </div>
          <div className="col-xs-12 col-sm-offset-1 col-sm-5">
            <h5>Du solltest mitbringen</h5>
            <ul className="list" style={{ fontSize: "1rem" }}>
              <li>Die Bereitschaft für Hausbesuche ist wünschenswert</li>
              <li>Die <i>Zertifizierung zur Manuellen Lymphdrainage oder Manuellen Therapie</i> wäre von Vorteil ist
                aber kein Muss.
              </li>
            </ul>
          </div>
          <div className="col-xs-12 col-sm-5">
            <h5>Wir bieten</h5>
            <ul className="list" style={{ fontSize: "1rem" }}>
              <li>Arbeiten als Basis oder auf Teilzeit</li>
              <li>Flexible Arbeitszeiten / Freie Zeiteinteilung</li>
              <li>Moderne, gemütliche und große Behandlungsräume sowie ein arbeitserleichterndes Betriebsystem!</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="section-content mb-30">
        <div className="row ml-md-0 multi-row-clearfix"/>
      </div>
    </div>
  </section>;
}

function renderParallaxDivider(image: "*.png" | "*.jpg") {
  return <section id="features" className="divider parallax layer-overlay overlay-dark-5"
                  data-bg-img={image} data-parallax-ratio="0.7">
      <div className="container pt-170 pb-170 pt-sm-100 pb-sm-100"/>
  </section>;
}
