import * as React from 'react';
import { ILink } from '../../models/Link';

interface INavbarProps {
  id: string;
  logoUrl: string;
  links: ILink[];
}

export function Navbar(props: INavbarProps) {
  const { id } = props;

  return <div id={id} className="header-nav">
    <div className="header-nav-wrapper navbar-scrolltofixed bg-lighter">
      <div className="container">
        <nav id="menuzord-right" className="menuzord orange no-bg">
          <a className="menuzord-brand pull-left flip" href="/"><img src={props.logoUrl}/></a>
          <ul className="menuzord-menu">
            {
              props.links.map(((link) => {
                return (
                    <li key={link.href}><a href={`${link.href}`}>{link.label}</a></li>
                );
              }))
            }
            {/*<li className="active"><a href="#home">Home</a></li> TODO: Show active link somehow*/}
          </ul>
        </nav>
      </div>
    </div>
  </div>;
}
