import * as React from 'react';

interface IContactFormProps {
  test?: string;
}

export function ContactForm(props: IContactFormProps) {
  return (
    <div className="contact-form">
      <form method="post" action="https://formspree.io/f/mrgwbokj">
        <input type="hidden" name="_subject" value="Kontaktanfrage Formspree" />
        <input type="text" name="_gotcha" style={{ display: 'none' }}/>

        <div className="row">
          <div className="col-sm-8">
            <div className="col-sm-6">
              <div className="form-group">
                <input  name="form_name" className="form-control" type="text" placeholder="Vorname und Nachname" required={true}/>
              </div>
              <div className="form-group">
                <input id="form_email" name="email" className="form-control required email" type="email" placeholder="E-Mail-Adresse" required={true}/>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input id="form_phone" name="form_phone" className="form-control" type="text" placeholder="Rufnummer (optional)"/>
              </div>
              <div className="form-group">
                <input id="form_subject" name="form_subject" className="form-control required" type="text" placeholder="Betreff" required={true}/>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <textarea id="form_message" name="form_message" className="form-control required" rows={5} placeholder="Was ist Ihr Anliegen?" style={{ height: '165px' }} required={true}/>
              </div>
              <div className="form-group mt-20">
                <button type="submit" className="btn btn-theme-colored mr-5" data-loading-text="Bitte warten...">Nachricht absenden</button>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <h3 className="mt-0 text-theme-colored font-weight-300">Kontakt-Informationen</h3>
            <p>Fühlen Sie sich frei uns unverbindlich zu schreiben oder in der Praxis zu besuchen.</p>

            <ul className="mt-30">
              <li><i className="fa fa-phone mb-20 text-theme-colored mr-5 font-20"/><a href="tel:0406310263">040-631 02 63</a></li>
              <li><i className="fa fa-map-marker mb-20 text-theme-colored mr-5 font-20"/>Bramfelder Chaussee 291, 22177 Hamburg</li>
              <li><i className="fa fa-envelope mb-20 text-theme-colored mr-5 font-20"/><a href="mailto:info@physiofit-bramfeld.de"> info@physiofit-bramfeld.de</a></li>
              <li><i className="fa fa-globe mb-20 text-theme-colored mr-5 font-20"/><a href="www.physiofit-bramfeld.de"> www.physiofit-bramfeld.de</a></li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  );
}
