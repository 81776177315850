import p3Png from "./p3.png";
import p5Png from "./p5.png";
import p7Png from "./p7.png";
import p8Png from "./p8.png";

export const PATTERN = {
  p3: p3Png,
  p5: p5Png,
  p7: p7Png,
  p8: p8Png
};
