import * as React from 'react';

interface ISectionTitleProps {
  style?: React.CSSProperties;
  title: string;
  subtitle: string;
}

const defaultProps: React.CSSProperties =  {
  paddingBottom: '2rem',
  paddingTop: '2rem',
};

export function SectionTitle(props: ISectionTitleProps) {
  const { title, subtitle } = props;

  return (
    <div style={Object.assign({}, defaultProps, props.style)}>
      <h2 className="text-uppercase title">{title}</h2>
      <p className="text-uppercase letter-space-4">{subtitle}</p>
    </div>
  );
}
