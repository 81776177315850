import * as React from 'react';
import { IOpeningHour } from '../../models/OpeningHours';

interface IContactBarProps {
  id: string;
  mail: string;
  openToday?: IOpeningHour | null;
  phone: string;
  style?: React.CSSProperties;
}

export function ContactBar(props: IContactBarProps) {
  const { openToday, id } = props;
  const openTodayText = openToday ? `${openToday.from.format('HH:mm')} - ${openToday.to.format('HH:mm')}` : "Geschlossen";

  return (
    <div id={id} className="header-top sm-text-center bg-theme-colored">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-4">
            <div className="widget m-0 mt-5 no-border">
              <ul className="list-inline text-right sm-text-center">
                <li className="pl-10 pr-10 mb-0 pb-0">
                  <div className="header-widget text-white">
                    <i className="fa fa-phone"/>
                    <a href="tel:0406310263" className="text-white"> {props.phone}</a>
                  </div>
                </li>
                <li className="pl-10 pr-10 mb-0 pb-0">
                  <div className="header-widget text-white">
                    <i className="fa fa-envelope-o"/>
                    <a href={`mailto:${props.mail}`} className="text-white"> Mail senden</a>
                  </div>
                </li>
                <li className="pl-10 pr-10 mb-0 pb-0">
                  <div className="header-widget text-white">
                    <i className="fa fa-clock-o"/> Heute: {openTodayText}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
