import * as React from 'react';
import {Component} from 'react';
import Physiofit1Jpg from "../../images/physio/praxis/Physiofit-1.jpg";
import Physiofit2Jpg from "../../images/physio/praxis/Physiofit-2.jpg";
import Physiofit4Jpg from "../../images/physio/praxis/Physiofit-4.jpg";
import Physiofit5Jpg from "../../images/physio/praxis/Physiofit-5.jpg";
import Physiofit7Jpg from "../../images/physio/praxis/Physiofit-7.jpg";
import Physiofit9Jpg from "../../images/physio/praxis/Physiofit-9.jpg";
import Physiofit10Jpg from "../../images/physio/praxis/Physiofit-10.jpg";
import Physiofit13Jpg from "../../images/physio/praxis/Physiofit-13.jpg";
import Physiofit14Jpg from "../../images/physio/praxis/Physiofit-14.jpg";

export class OfficeImagesRevolutionSlider extends Component {

  /**
   * Reference to jQuery.
   */
  private readonly jquery: any;

  /**
   * ID of the div that contains the `ul` of the revolution slider to configure.
   */
  private readonly sliderId: string;

  /**
   * Revolution slider configuration
   */
  private readonly config: any;

  constructor(props: any) {
    super(props);
    // @ts-ignore
    this.jquery = jQuery || $ || window.jQuery || window.$;
    if (!this.jquery) {
      throw new TypeError("No jQuery object found!");
    }

    this.sliderId = "rev_slider_praxis";
    this.config = {
      sliderType: "standard",
      jsFileLocation: "js/revolution-slider/js/",
      sliderLayout: "auto",
      dottedOverlay: "none",
      delay: 5000,
      navigation: {
        keyboardNavigation: "off",
        keyboard_direction: "horizontal",
        mouseScrollNavigation: "off",
        onHoverStop: "off",
        touch: {
          touchenabled: "on",
          swipe_threshold: 75,
          swipe_min_touches: 1,
          swipe_direction: "horizontal",
          drag_block_vertical: false
        },
        arrows: {
          style: "gyges",
          enable: true,
          hide_onmobile: false,
          hide_onleave: true,
          hide_delay: 200,
          hide_delay_mobile: 1200,
          tmp: '',
          left: {
            h_align: "left",
            v_align: "center",
            h_offset: 0,
            v_offset: 0
          },
          right: {
            h_align: "right",
            v_align: "center",
            h_offset: 0,
            v_offset: 0
          }
        },
        bullets: {
          enable: true,
          hide_onmobile: true,
          hide_under: 800,
          style: "hebe",
          hide_onleave: false,
          direction: "horizontal",
          h_align: "center",
          v_align: "bottom",
          h_offset: 0,
          v_offset: 30,
          space: 5,
          tmp: '<span class="tp-bullet-image"></span><span class="tp-bullet-imageoverlay"></span><span class="tp-bullet-title"></span>'
        }
      },
      responsiveLevels: [1240, 1024, 778],
      visibilityLevels: [1240, 1024, 778],
      gridwidth: [1170, 1024, 778, 480],
      gridheight: [1170, 768, 960, 720],
      lazyType: "none",
      parallax: "mouse",
      parallaxBgFreeze: "off",
      parallaxLevels: [2, 3, 4, 5, 6, 7, 8, 9, 10, 1],
      shadow: 0,
      spinner: "off",
      stopLoop: "on",
      stopAfterLoops: 0,
      stopAtSlide: -1,
      shuffle: "off",
      autoHeight: "off",
      fullScreenAutoWidth: "off",
      fullScreenAlignForce: "off",
      fullScreenOffsetContainer: "",
      fullScreenOffset: "0",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        nextSlideOnWindowFocus: "off",
        disableFocusListener: false,
      }
    };
  }

  public componentDidMount(): void {
    // TODO jQuery is probably not needed for this task!
    this.jquery(document).ready(() => {
      this.jquery(`#${this.sliderId}`).revolution(this.config);
    });
  }

  public render() {
    const images = [
      Physiofit1Jpg,
      Physiofit2Jpg,
      Physiofit4Jpg,
      Physiofit5Jpg,
      Physiofit7Jpg,
      Physiofit9Jpg,
      Physiofit10Jpg,
      Physiofit13Jpg,
      Physiofit14Jpg,
    ];

    const elements = images.map((image, index) => {
      return OfficeImagesRevolutionSlider.renderSlide(image);
    });

    return (
      <section className="divider">
        <div className="container-fluid p-0">
          <div className="rev_slider_wrapper">
            <div id="rev_slider_praxis" data-version="5.0">
              <ul>
                { elements }
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }

  private static renderSlide(image: any) {
    return <li>
      <img src={image} alt="" data-bgposition="center"
           data-bgfit="cover"
           data-bgrepeat="no-repeat" className="rev-slidebg" data-bgparallax="6"
           data-no-retina="true"/>
    </li>;
  }
}
