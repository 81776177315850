import * as React from 'react';
import './styles/css/index.css';
import {Section} from './components/section/Section';
import {SectionTitle} from './components/section-title/SectionTitle';
import {ContactBar} from './components/contact-bar/ContactBar';
import {Navbar} from './components/navbar/Navbar';
import logoPng from "./images/physio/logo.png";
import {IService} from "./models/Service";
import {MasterDataService, SERVICES} from "./services/MasterDataService";
import {Impressum} from "./pages/Impressum";
import {Datenschutz} from "./pages/Datenschutz";
import { Home } from './pages/Home';

class App extends React.Component {

  private services: IService[] = []; // FIXME: Load from MasterDataService

  constructor(props: any) {
    super(props);
    this.services = SERVICES;
  }

  public render() {
    const openToday = MasterDataService.getCurrentOpeningHour();

    const links = [
      {
        href: '/#home',
        label: 'Home'
      },
      {
        href: '/#services',
        label: 'Leistungen'
      },
      {
        href: '/#wellness',
        label: 'Wellness'
      },
      {
        href: '/#pricing',
        label: 'Preise'
      },
      {
        href: '/#praxis',
        label: 'Praxis'
      },
      {
        href: '/#contact',
        label: 'Kontakt'
      }
    ];

    const content = this.chooseContent();

    // FIXME: The logoUrl is invalid
    return (
      <div className="App">
        <ContactBar id="contact-bar" phone="040-631 02 63" mail="info@physiofit-bramfeld.de" openToday={openToday}/>
        <Navbar id="navbar" links={links} logoUrl={logoPng}/>

        {content}

        {App.renderFooter()}

        <a className="scrollToTop" href="#"><i className="fa fa-angle-up"/></a>
      </div>
    );
  }

  private chooseContent() {
    const location = window.location;
    const path = location.pathname;

    // maps paths onto the renders responsible
    const routes = {
      "/": this.renderViewIndex(),
      "/index": this.renderViewIndex(),
      "/impressum": this.renderViewImpressum(),
      "/datenschutz": this.renderViewDatenschutz(),
    };

    const renderer = routes[path];
    if (!renderer) {
      console.warn(`Route for path ${path} not found`);
      return this.renderViewIndex();
    }
    return renderer;
  }

  private renderViewIndex() {
    return Home({
      services: this.services
    });
  }

  private renderViewImpressum() {
    return (
      <div>
        <Section id="impressum">
          <SectionTitle title="Impressum" subtitle="Impressum und Datenschutz"/>
          <Impressum/>
        </Section>
      </div>
    );
  }

  private renderViewDatenschutz() {
    return (
      <div>
        <Section id="datenschutz">
          <SectionTitle title="Datenschutz" subtitle="Datenschutz"/>
          <Datenschutz/>
        </Section>
      </div>
    );
  }

  private static renderFooter() {
    return <footer id="footer" className="footer bg-black-222">
      <div className="container pb-50">
        <div className="row border-bottom-black">
          <div className="col-sm-6 text-left">
            <div className="widget dark">
              <img className="mt-10 mb-20" alt="" src={logoPng} style={{ maxWidth: "300px" }}/>
                <p>Bramfelder Chaussee 291<br/>22177 Hamburg</p>
                <ul className="list mt-5">
                  <li className="m-0 pl-10 pr-10"><i className="fa fa-phone text-theme-colored mr-5"/> <a
                    className="text-gray" href="tel:0406310263">040-631 02 63</a></li>
                  <li className="m-0 pl-10 pr-10"><i className="fa fa-envelope-o text-theme-colored mr-5"/> <a
                    className="text-gray" href="mailto:info@physiofit-bramfeld.de">info@physiofit-bramfeld.de</a></li>
                  <li className="m-0 pl-10 pr-10"><i className="fa fa-globe text-theme-colored mr-5"/> <a
                    className="text-gray" href="www.physiofit-bramfeld.de">www.physiofit-bramfeld.de</a></li>
                </ul>
                <p><a href="./impressum">Impressum</a></p>
                <p><a href="./datenschutz">Datenschutz</a></p>
            </div>
          </div>
          <div className="col-sm-4 col-sm-offset-2">
            <div className="widget dark">
              <h5 className="widget-title line-bottom">Öffnungszeiten</h5>
              <div className="opening-hours">
                <ul className="list-border">
                  <li className="clearfix"><span> Montag - Donnerstag :  </span>
                    <div className="value pull-right"> 8:00 - 13:00<br/>14:00 - 19:00</div>
                  </li>
                  <li className="clearfix"><span> Freitag :</span>
                    <div className="value pull-right"> 8:00 - 12:00</div>
                  </li>
                </ul>
                <p style={{ marginTop: ".5rem" }}>Termine nach Vereinbarung</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid copy-right p-20 bg-black-333">
        <div className="row text-center">
          <div className="col-md-12">
            <p className="font-11 text-black-777 m-0">Webseite entworfen und umgesetzt durch <a
              href="http://www.fabitee.de" style={{ color: "#086ca2" }}>Fabitee Webdesign &amp; Webhosting</a></p>
          </div>
        </div>
      </div>
    </footer>;
  }
}

export default App;
